<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Cargar Comisión</h1>
            <div class="info-card" v-if="sale_point.id">
                <h3>Datos de la alianza</h3>
                <hr>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Nombre</p></div>
                    <div class="col-md-9"><b>{{sale_point.name}}</b></div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-3"><p>Ciudad</p></div>
                    <div class="col-md-9"><b>{{sale_point.city_name}}</b></div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button v-if="session_user.pages.commision_many && sale_point.modality == 'percents'"
                        class="btn btn-primary" style="width: 100%;"
                        @click="showCommModal()">Cargar archivo</button>
                    </div>
                </div>
            </div>
            <div class="info-card" v-else>
                <div style="display: flex; justify-content: center; ">
                    <div class="lds-dual-ring"></div>
                </div>
            </div>
            <div class="info-card">
                <div class="row">
                    <div class="col-md-12">
                        <p>Cliente</p>
                    </div>
                    <div class="col-md-3" style="padding-right: 0;">
                        <select class="form-control" v-model="search_type ">
                            <option value="code">Código</option>
                            <option value="card">Identificación</option>
                        </select>
                    </div>
                    <div class="col-md-9" style="padding-left: 0;">
                        <input type="number" v-model="search_code" class="form-control" placeholder="Buscar usuario...">
                    </div>
                    <div class="col-md-12">
                        <select class="form-control" v-model="sale.client_user_id">
                            <option value="" selected v-if="users.length <= 0 && search_code">Usuario no encontrado</option>
                            <option value="" selected disabled v-if="users.length <= 0 && !search_code">Buscar usuario..</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">
                                <span v-if="search_type == 'code'">{{user.id +' | '+ user.name + ' ' + user.last_name}}</span>
                                <span v-else>{{user.id_card +' | '+ user.name + ' ' + user.last_name}}</span>
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2" v-if="sale_point.modality == 'percents'">
                    <div class="col-sm-7">
                        <p>Valor de la compra</p>
                        <input v-model="sale_amount" type="number" class="form-control">
                    </div>
                    <div class="col-sm-5">
                        <p>Porcentaje</p>
                        <select class="form-control" v-model="sale_percent">
                            <option value="" disabled>Seleccionar porcentaje</option>
                            <option :value="option.percent" v-for="option in sale_point.options" :key="option.id">{{option.percent}} %</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2" v-if="sale_point.modality == 'options'">
                    <div class="col-sm-6">
                        <p>Opción</p>
                        <select class="form-control" v-model="sale_option">
                            <option value="" disabled>Seleccionar opción</option>
                            <option :value="option.money" v-for="option in sale_point.options" :key="option.id">{{option.description}}</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <p>Cantidad de Unidades</p>
                        <input type="number" class="form-control" v-model="unit_amount">
                    </div>
                </div>
                <div class="row mt-2" v-if="sale_point.modality == 'free'">
                    <div class="col-sm-12">
                        <p>Comisión</p>
                        <input type="number" class="form-control" v-model="sale_amount">
                    </div>
                </div>
                <div class="form-group mt-3" style="text-align: right;">
                    <button class="btn btn-primary" style="min-width: 180px" @click="registerSale(sale)">Guardar</button>
                </div>
            </div>
        </main>
        <!--------------MODA FILE XLSX-->
        <div class="modal fade" id="modal-file" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">CARGAR ARCHIVO</h3>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <a href="#" class="btn btn-primary mb-2" style="display: flex; align-items: center; max-width: 200px;">
                                    <span class="material-icons-sharp">&#xe2c4;</span> Descargar Formato
                                </a>
                            </div>
                            <div class="col-md-7">
                                <div class="custom-file mb-3">
                                    <input @change="previewFile()" type="file" class="custom-file-input" id="xlsxFile" accept=".xlsx" required>
                                    <label class="custom-file-label" for="file-xlsx">Selecciona archivo .xlsx</label>
                                </div>  
                            </div>
                            <div class="col-md-5">
                                <select class="form-control" v-model="file.percent">
                                    <option value="" disabled>Seleccionar porcentaje</option>
                                    <option :value="option.percent" v-for="option in sale_point.options" :key="option.id">{{option.percent}} %</option>
                                </select>
                            </div>
                        </div>
                        <!-------TABLA------>
                        <div :class="{'d-none':(file.loading && file.loaded != file.data.length)}" v-if="(file.loaded_success.length <= 0 || file.loaded_success <= 0)">
                            <table class="table table-bordered" id="table-file">
                                <thead>
                                    <tr>
                                        <th>Identificación</th>
                                        <th>Nombre</th>
                                        <th>Comisión</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td colspan="3" class="text-center">Selecciona un archivo</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="file.loading">
                            <div style="display: flex; justify-content: center; ">
                                <div class="lds-dual-ring"></div>
                            </div>
                        </div>
                        <!-------MENSAJES------->
                        <div v-if="file.loaded == file.data.length && file.data.length > 0">
                            <div class="card text-white bg-success mb-3">
                                <div class="card-header">¡Se registraron {{file.loaded_success.length}} ventas exitosamente!</div>
                            </div>
                            <div class="card text-white mb-3">
                                <div class="card-header bg-danger">¡Surgieron {{file.loaded_errors.length}} errores en la carga de ventas!</div>
                                <div style="padding:.5rem;">
                                    <table class="table table-striped" id="table-errors" style="margin:0;">
                                        <thead>
                                            <tr>
                                                <th>Identificación</th>
                                                <th>Nombre</th>
                                                <th>Comisión</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="loadFileComm()"
                        v-if="(file.loaded != file.data.length) && !file.loading">CARGAR COMISIONES</button>
                        <button class="btn btn-primary" @click="restartFile()"
                        v-if="file.loaded == file.data.length && file.data.length > 0">FINALIZAR</button>
                    </div>
                </div>
            </div>
        </div>
        <Right/>
    </div>
</template>
<script>
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';
export default {
    name: 'LoadCommissions',
    components: {
        Aside,
        Right,
    },
    data: function (){
        return {
            id: '',
            search_type: 'code',
            search_code : '',
            waiting_search: false,
            users: [],
            file: {
                loading: false,
                loaded: 0,
                loaded_errors: [],
                loaded_success: [],
                percent: '',
                data: [],
            },
            sale_percent: '',
            sale_option: '',
            sale_amount: '',
            unit_amount: '',
            sale: {
                seller_user_id: '',
                client_user_id: '',
            }
        }
    },
    methods: {
        loadErrorTable(){
            $('#table-errors').DataTable({
                data: this.file.loaded_errors,
                destroy: true,
                autoWidth: false,
                columns: [
                    {"data": "id_card"},
                    {"data": "name"},
                    {"data": "money"},
                ]
            })
        },
        restartFile(){
            this.file.loading = false;
            this.file.loaded = 0;
            this.file.loaded_errors = [],
            this.file.loaded_success = [],
            this.file.percent = '';
            this.file.data = [];
            document.getElementById('xlsxFile').value = '';
            $('#modal-file').modal('hide');
            $('#table-file').DataTable({
                data: [],
                destroy: true,
                autoWidth: false,
            });
        },
        loadFileComm(){
            let percent = this.file.percent;
            if(!percent) return this.launchAlert({type: 'warning', title: 'Selecciona un porcentaje'});
            this.$swal.fire({
                title: `¿Deseas cargar este archivo?`,
                html: `Cargar <b>${this.file.data.length}</b> registros con un porcentaje de <b>${percent}%</b>`,
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    // ENVÍA 5 REGISTROS POR SEGUNDO
                    let i = 0;
                    this.file.loading = true;
                    let time = setInterval(() => {
                        let data = this.file.data[i];
                        let description = description = `${data.money} x ${percent}%`;
                        let sale_total = data.money * (percent / 100);
                        let json = {
                            id_field: 'id_card',
                            purchase_price: 0,
                            selling_price: sale_total,
                            seller_user_id: this.session_user.id,
                            client_user_id: data.id_card,
                            sale_point_id: this.$route.params.id,
                            description: description
                        }
                        this.createSale(json).then(res => {
                            this.file.loaded_success.push(data);
                            if((this.file.loaded_errors.length + this.file.loaded_success.length) == this.file.data.length)this.loadErrorTable();
                        }).catch(e=> {
                            this.file.loaded_errors.push(data);
                            if((this.file.loaded_errors.length + this.file.loaded_success.length) == this.file.data.length)this.loadErrorTable();
                        });
                        i++;
                        this.file.loaded = i;
                        if(i >= this.file.data.length) {
                            this.file.loading = false;
                            clearInterval(time);
                        }

                    }, 200);
                }
            });
        },
        previewFile(){
            var file = document.getElementById('xlsxFile').files[0];
            if(!file) return;
            readXlsxFile(file).then(rows => {
                let array = [];
                rows.forEach(col => {
                    if(!parseInt(col[2])) return;
                    let json = {
                        "id_card": col[0],
                        "name": col[1],
                        "money": col[2],
                    }
                    array.push(json);
                });
                this.file.data = array;
                $('#table-file').DataTable({
                    data:array,
                    destroy: true,
                    autoWidth: false,
                    columns: [
                        {"data": "id_card"},
                        {"data": "name"},
                        {"data": "money"},
                    ]
                });
            })
        },
        showCommModal(){$('#modal-file').modal('show');},


        registerSale(sale){
            if(!sale.client_user_id) return this.launchAlert({type: 'warning', title: 'Selecciona un cliente'});
            let description = '';
            let sale_total = '';
            let message = '';

            if(this.sale_point.modality == 'percents'){
                if(this.sale_amount < 100) return this.launchAlert({type: 'warning', title: 'Comisión es demasiado pequeña para ser procesada'});
                if(!this.sale_amount) return this.launchAlert({type: 'warning', title: 'Digita un valor de comisión'});
                if(!this.sale_percent) return this.launchAlert({type: 'warning', title: 'Selecciona un porcentaje'});

                sale_total = this.sale_amount * (this.sale_percent / 100);
                description = `${this.sale_amount} x ${this.sale_percent}%`;
                message = `<h3> El <b>${this.sale_percent}%</b> del valor <b>$${this.sale_amount}</b> dará 
                como resultado una comisión base de <b>$${sale_total}</b>, 
                <b>¿Desea procesar esta comisión al usuario con código ${sale.client_user_id}?</b></h3>`;
            }

            if(this.sale_point.modality == 'options'){
                if(this.sale_option < 100) return this.launchAlert({type: 'warning', title: 'Comisión es demasiado pequeña para ser procesada'});
                if(!this.sale_option) return this.launchAlert({type: 'warning', title: 'Selecciona una opcion'});
                if(!this.unit_amount) return this.launchAlert({type: 'warning', title: 'Digita una cantidad'});

                sale_total = this.sale_option * this.unit_amount;
                description = `${this.sale_option} x ${this.unit_amount}`;
                message = `<h3>El valor de <b>$${description}</b> dará 
                como resultado una comisión base de <b>$${sale_total}</b>, 
                <b>¿Desea procesar esta comisión al usuario con código ${sale.client_user_id}?</b></h3>`;
            }
            if(this.sale_point.modality == 'free'){
                if(this.sale_amount < 100) return this.launchAlert({type: 'warning', title: 'Comisión es demasiado pequeña para ser procesada'});
                sale_total = this.sale_amount;
                description = sale_total;
                message = `<h3>La comisión es de <b>$${sale_total}</b>, 
                <b>¿Desea procesar esta comisión al usuario con código ${sale.client_user_id}?</b></h3>`;
            }

            let json = {
                purchase_price: 0,
                selling_price: sale_total,
                seller_user_id: this.session_user.id,
                client_user_id: sale.client_user_id,
                sale_point_id: this.$route.params.id,
                description: description
            }
            this.confirmSale(json, message);
        },
        confirmSale(sale, message){
            this.$swal.fire({
                title: '¿Deseas cargar esta comisión?',
                html: message,
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.createSale(sale).then(res => {
                        if(res.status == 201){
                            this.search_code = '';
                            this.sale_percent = '';
                            this.sale_amount = '';
                            this.unit_amount = '';
                            this.sale_option = '';
                            this.sale.client_user_id = '';
                            return this.launchAlert({type: 'success', title: 'Comisión registrada exitosamente'});
                        }
                    }).catch(err => {
                        console.log(err.response);
                    })
                }
            })
        },
        searchUser(search){
            this.waiting_search = true;
            let url = this.api_url+'/api/search/public/users';
            axios.post(url, search, 
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.waiting_search = false;
                this.users = res.data;
                if(res.data.length > 0) this.sale.client_user_id = res.data[0].id;
                else this.sale.client_user_id = '';
            }).catch(e=> console.log(e.response))
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapActions("sale", ["createSale"]),
        ...mapMutations(['loadSessionUser']),
        ...mapMutations("sale_point", ["loadSalePoint"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        this.loadSalePoint(this.$route.params.id);
    },
    computed: {
        ...mapState(['api_url']),
        ...mapState(['session_user']),
        ...mapState('sale_point', ['sale_point']),
    },
    watch: {
        search_code: function (){
            if(!this.search_code) {
                this.sale.client_user_id = '';
                return this.users = []
            };
            this.searchUser({search : this.search_code, type_search: this.search_type, order: 'ASC'});
        },
        '$route.params.id':function (){
            this.loadSalePoint(this.$route.params.id);
        }
    }
}
</script>